import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const [registro, setRegistro] = useState({
    negocio: '',
    password: '',
    telefono: '',
    email: ''
  });
  const [mensajeRegistro, setMensajeRegistro] = useState('');
  const [login, setLogin] = useState({ usuario: '', contrasena: '' });
  const navigate = useNavigate();

  // Función para registrar un nuevo dueño para la demo
const registrarDemo = async (e) => {
  e.preventDefault();

  try {
    const res = await fetch('https://mireserva.app/api/registro-demo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
body: JSON.stringify({
  usuario: registro.email,
  nombre: registro.negocio,
  telefono: registro.telefono,
  password: registro.password
      }),
    });

    if (!res.ok) throw new Error('Error al crear demo');
    const data = await res.json();
    setMensajeRegistro('Demo creada. Ingresá con el usuario y contraseña que elegiste.');

    if (data.redirect) {
      setTimeout(() => navigate(data.redirect), 1000);
    }
  } catch (err) {
    alert('Error al registrar la demo. Verificá los campos o intentá más tarde.');
  }
};

  // Función para login de dueños y alumnos
  const loginUsuario = async (e, tipoUsuario) => {
    e.preventDefault();

    try {
      const res = await fetch('https://mireserva.app/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usuario: login.usuario, contrasena: login.contrasena }),
      });

      if (!res.ok) throw new Error('Credenciales incorrectas');
      const data = await res.json();
      localStorage.setItem('user', JSON.stringify(data));

      // Establecer el tipo de usuario y redirigir según el tipo
      if (tipoUsuario === 'duenio') {
        navigate('/calendario');
      } else {
        navigate('/alumno');
      }
    } catch (err) {
      alert('Credenciales incorrectas');
    }
  };

  return (
    <div className="container mx-auto p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Registro de demo */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-center">Probar demo gratuita</h2>
          <form onSubmit={registrarDemo} className="space-y-4">
            <input
              type="text"
              placeholder="Nombre del negocio"
              value={registro.negocio}
              onChange={(e) => setRegistro({ ...registro, negocio: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <input
              type="password"
              placeholder="Crear contraseña"
              value={registro.password}
              onChange={(e) => setRegistro({ ...registro, password: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              placeholder="Teléfono"
              value={registro.telefono}
              onChange={(e) => setRegistro({ ...registro, telefono: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={registro.email}
              onChange={(e) => setRegistro({ ...registro, email: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <button
              type="submit"
              className="w-full bg-yellow-500 text-white p-3 rounded-md hover:bg-yellow-400"
            >
              Probar demo
            </button>
          </form>
          {mensajeRegistro && <p className="mt-4 text-green-500">{mensajeRegistro}</p>}
        </div>

        {/* Login */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-center">Ingresar</h2>
          {/* Login para Dueño */}
          <form onSubmit={(e) => loginUsuario(e, 'duenio')} className="space-y-4">
            <input
              type="text"
              placeholder="Usuario"
              value={login.usuario}
              onChange={(e) => setLogin({ ...login, usuario: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={login.contrasena}
              onChange={(e) => setLogin({ ...login, contrasena: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <button
              type="submit"
              className="w-full bg-yellow-500 text-white p-3 rounded-md hover:bg-yellow-400"
            >
              Ingresar como dueño
            </button>
          </form>

          {/* Login para Alumno */}
          <form onSubmit={(e) => loginUsuario(e, 'alumno')} className="mt-4 space-y-4">
            <input
              type="text"
              placeholder="Usuario"
              value={login.usuario}
              onChange={(e) => setLogin({ ...login, usuario: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={login.contrasena}
              onChange={(e) => setLogin({ ...login, contrasena: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
            <button
              type="submit"
              className="w-full bg-yellow-500 text-white p-3 rounded-md hover:bg-yellow-400"
            >
              Ingresar como alumno
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
